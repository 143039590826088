@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);

/*********************** transition **********************/

.fade-enter {
    opacity: .01
}

.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity .5s ease-in
}

.fade-exit {
    opacity: 1
}

.fade-exit.fade-exit-active {
    opacity: .01;
    transition: opacity .5s ease-in
}

/*********************** structure **********************/

* {
    margin: 0;
    padding: 0;
}

body {
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden
}

header {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
}

header,
header>nav {
    width: 100%;
    height: 80px;
}

header>nav {
    background-color: #fff
}

header>nav ul {
    width: 80%;
    height: 80px;
    margin: 0 auto;
    display: flex;
    list-style: none;
}

header>nav ul li {
    flex: 1;
}

header>nav ul li a {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 80px;
    color: #000;
    text-decoration: none;
}

header>nav ul li a:focus{
    border: none;
    outline: none;
}

header>nav ul li .active,
header>nav ul li a:hover {
    background-color: #2c2d59;
    color: #fff;
}

#showTime{
    background-color: #2c2d59;
    color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
}

#header-social{
    position: absolute;
    right: 15px;
    top: 7px;
}

#header-social a{
    margin: 0 2px;
}

#showTime .container{
    width: 100%;
    text-align: center;
}

.showTitle{
    font-weight: bolder;
}

.showContent{
    color: white;
}

.showLink a{
    color: white;
}

.mylogo {
    background-color: #fff !important;
}

#container-logo {
    display: flex;
}

#container-logo:hover {
    background-color: #fff;
}

#logo {
    width: 55px;
    height: 55px;
    margin: auto;
}

#moodle {
    width: 25px;
    height: 17px;
}

#mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    width: 100%;
    height: 80px;
}

#mobile nav {
    margin: 0 auto;
    width: 90%;
    height: 100%;
}

#burger {
    width: 40px;
    cursor: pointer
}

#burger .bar {
    width: 100%;
    height: 5px;
    background-color: #000;
    margin-top: 4px;
    transition: all 1s
}

.activeMenu .bar:first-of-type {
    transform: rotate(45deg) translate(9px, 10px)
}

.activeMenu .bar:nth-of-type(2) {
    opacity: 0
}

.activeMenu .bar:nth-of-type(3) {
    transform: rotate(-45deg) translate(2px, -5px)
}

.menu-mobile {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #212240;
    color: #fff;
    top: 0;
    left: -100vw;
    z-index: 100;
    transition: all 1s;
    display: flex
}

.menu-mobile ul {
    width: 80%;
    margin: auto;
    list-style: none;
    text-align: center
}

.menu-mobile ul li a {
    color: #fff;
    font-size: 20px;
    text-decoration: none;
    line-height: 70px;
    font-weight: bolder
}

.open-menu {
    left: 0
}

footer {
    width: 100%;
    min-height: 220px
}

footer #service {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 180px;
    background-color: #2c2d59
}

footer #service .wrapper {
    width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 40px
}

#footer-epse {
    grid-column: 1/span 2
}

#footer-epse img{
    margin: 0 !important;
}

.container-logos{
    display: flex;
    margin-top: 10px;
    margin-left: 5px;
}

.container-logos .footer-logo:nth-child(1)
{
    padding-right: 15px;
}

.container-logos .footer-logo:nth-child(2), .container-logos .footer-logo:nth-child(3){
    padding: 0 15px;
    border-left: solid 2px white;
}

.partners-link{
    display: block !important;
}

#footer-social {
    grid-column: 5/span 2;
    text-align: center
}

#footer-social .social-link{
    display: inline !important;
}

#footer-contact {
    grid-column: 7/span 2
}

footer #f-link {
    min-height: 40px;
    background-color: #212240;
    line-height: 40px;
    font-size: 15px;
    color: silver
}

footer #f-link a {
    color: silver;
    text-decoration: none
}

footer #f-link .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center
}

footer #service .container div {
    width: 20%;
    min-height: 180px
}

footer #service p {
    margin-top: 5px;
    color: #fff;
    font-size: 15px;
    padding: 5px
}

footer #service a {
    display: block;
    color: #fff;
    text-decoration: none;
    line-height: 30px;
    font-size: 15px
}

footer #service h2 {
    padding: 5px 0;
    color: #fff;
    font-size: 20px
}

footer #service img {
    margin-top: 10px;
    margin-left: 5px
}

footer #links a {
    color: #fff
}

.pagination{
    width: 320px;
    margin: 40px auto 0;
    
}

.pagination ul{
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
}

.pagination ul li{
    text-align: center;
    width: 30px;
    line-height: 30px;
    cursor: pointer;
    margin-right: 5px;
}

.pagination .is-active{
    background-color: #353668;
    color: white;
}

/*********************** home page  **********************/

#mobile-cube{
    display: none;
    margin-left: auto;
    margin-right: auto;
}

#home {
    min-height: 90vh;
    background-image: url(vector.svg);
    background-size: cover;
    background-position: 50%;
}

#formations-home,
#home {
    width: 100%;
    padding-top: 15px;
}

#formations-home {
    min-height: 60vh;
    background: white;
    padding-bottom: 15px;
}

.title {
    width: 100%;
    text-align: center;
    color: #fff;
    padding-top: 80px;
}

.event .title {
    width: 100%;
    text-align: center;
    color: #fff;
    padding-top: 0;
}

.title img {
    margin-top: 25px
}

.title h1 {
    font-weight: 700;
    font-size: 35px;
    margin-top: 20px;
}

.title h3 {
    width: 55%;
    margin: 20px auto 40px;
    text-align: center;
    font-weight: 300
}

#organisation{
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    color: #fff;
    text-align: center;
    margin: 50px auto 0
}

#home-actu{
    width: 1200px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    color: black;
    text-align: center;
    margin: 50px auto 0;
    min-height: 400px;
   
}

.home-actu{
    background-color: blue;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 270px;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    overflow: hidden;
}

.home-actu:hover .home-actu-info{
    top: 0;
}


.home-actu-title{
    position: absolute;
    width: 100%;
    color: white;
    padding: 10px 0;
    font-weight: bold;
    bottom: 10%;
    background-color: #282959cc;
    z-index: 3;
    transition: all 1s;
}

.home-actu:hover .home-actu-title{
    opacity: 0;
}

.home-actu-info{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #282959cc;
    top: 100%;
    left: 0;
    transition: all 1s;
    z-index: 4;
    color: white;
    box-sizing: border-box;
    padding: 30px 20px 0;
}

.home-actu-info article{
    box-sizing: border-box;
    padding: 15px;
    text-align: left;
    font-size: 15px;
}

.home-actu-img{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
}

.home-actu-img img{  
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s;
}

.home-actu:hover .home-actu-img img{
    transform: scale(1.1);
}

#home-actu h1{
    grid-column: 1 / span 3;
}

#actu,
#contact,
#evenements, .promo{
    display: flex;
    align-content: center;
    justify-content: center;
    height: 270px;
    flex-direction: column;
    text-decoration: none
}

#organisation h1 {
    margin-top: 15px;
    color: #fff;
    text-decoration: none;
    transition: all 1s;
}

#actu {
    background-color: #283479
}

.promo{
    overflow: hidden;
    position: relative;
}

.promo-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transition: all 0.2s ease-in;
}

.promo-title h1{
    width: 50%;
    padding: 15px;
    text-align: center;
    position: absolute;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
}

#sup{
    background-image: url("../public/images/EnseignementSup.jpg"); 
}

.promo:hover .promo-img{
   transform: scale(1.1);
}

#qual{
    background-image: url("../public/images/EnseignementQualifiant.jpg");
}

#tous{
    background-image: url("../public/images/All.jpg");
}

#organisation img {
    height: 70px;
    margin: 0 auto;
    transition: all 1s;
}

#actu:hover img,
#contact:hover img,
#evenements:hover img
{
    transform: scale(1.2);
}




#evenements {
    background-color: #27274d
}

#contact {
    background-color: #283479
}

#calendar {
    padding-top: 60px
}

#calendar h1 {
    text-align: center
}

#events {
    width: 920px;
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto;
    justify-content: space-between;
    color: #333333;
}

#all-events{
    display: flex; 
    justify-content: space-between;
}

#all-events .event{
    margin-top: 100px;
}

.event {
    text-align: center;
    width: 210px
}

.event .title {
    margin-top: 10px;
    min-height: 30px;
    color: #333333;
}

.event .btn a {
    color: #27274d;
    text-decoration: none;
    font-weight: bolder
}

#all-events .event{
    border: solid 1px black;
    width: 250px;
}

#all-events .event .event-date{
    color: #282959;
    padding-top: 15px;
    font-size: 16px;
}

#all-events .event .title{
    color: #282959;
    padding: 0;
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 15px;
    min-height: auto;
    font-weight: bold;
}

#all-events .event .event-date h3{
    margin: 5px 0;
}

#all-events .event .btn{
    background-color: #95a6db54;
    padding: 10px 0;
}

#calendar>.btn {
    width: 255px;
    font-size: 18px;
    background-color: #27274d;
    color: #fff;
    text-align: center;
    padding: 5px 15px;
    margin: 30px auto 10px;
    display: block;
    text-decoration: none;
    font-weight: bolder;
}

#info {
    margin-top: 50px;
    height: 330px;
    background-image: url(map.png);
    background-size: cover;
    background-position: 50%;
    position: relative;
    color: #333333;
}

#info,
#info a {
    width: 100%;
    z-index: 1
}

#info a {
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

#pratiques {
    z-index: 2;
    position: absolute;
    top: 45px;
    left: 25%;
    padding: 0 15px;
    width: 248px;
    height: 236px;
    background-color: #fff;
    box-shadow: 5px 5px 10px #000
}

.social-inline{
    display: inline !important;
}

#pratiques h2 {
    margin-top: 15px
}

#pratiques a {
    position: relative;
    display: block;
    width: 100%;
    height: 35px;
    margin-top: 10px;
    color: #000;
    text-decoration: none;
    display: flex;
    align-items: center
}

#pratiques img {
    margin-top: 10px;
    margin-right: 5px
}

/*********************** 404 **********************/

.not-found {
    height: 60vh;
    margin-top: 200px
}

.not-found .container {
    width: 1200px;
    margin: 0 auto
}

.not-found h1, .not-found h2{
    margin: 15px 0;
}

.not-found a{
    color: #323386;
    text-decoration: none;
    font-weight: bolder;
}

/*********************** formations **********************/

#formations-home .container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 15px
}

#formations-home h1,
#formations-home h2 {
    color: #fff;
    margin: 15px 0
}

#formations-home .form-control {
    font-size: 16px;
    width: 60%;
    padding: 5px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #2c2d59;
    color: silver;
    margin-bottom: 15px;
}

#formations-home #categories {
    display: flex;
    flex-wrap: wrap;
    width: 100%
}

#formations-home .category {
    padding: 15px 20px;
    width: 250px;
    color: #27297d;
    background-color: #95a7db;
    text-align: center;
    font-weight: bolder;
    margin: 5px 10px 5px 0;
    cursor: pointer;
    transition: all .5s;
    display: flex;
    text-transform: uppercase;
}

#formations-home .category span {
    margin: auto
}

#formations-home .category:hover {
    background-color: #2C2D59;
    color: #fff
}

#formations-home #categories .active {
    background-color: #2C2D59;
    color: #fff
}

#formations-home #formats {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
   
}

.ens #formats{
    justify-content: space-evenly;
}

#formations-home #formats .formation {
    width: 350px;
    height: 350px;
    background-color: silver;
    margin-top: 25px;
    margin-left: 75px;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
    text-transform: uppercase;
}

#formations-home #formats .formation:nth-child(3n+1){
    margin-left: 0px;
    
}

#formations-home #formats .formation a {
    text-decoration: none
}

#formations-home #formats .formation .f-title {
    font-weight: bolder;
    text-align: center;
    color: #fff;
    padding: 15px 0;
    position: relative;
    z-index: 2
}

#formations-home #formats .formation .f-back {
    width: 100%;
    height: 100%
}

#formations-home #formats .formation .f-back img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 1s
}

#formations-home #formats .formation .f-back img:hover {
    transform: scale(1.1)
}

/*************************** formation **********************/

#formation-page {
    padding-top: 80px;
    min-height: 60vh;
    padding-bottom: 80px
}

#formation-page .container {
    width: 1200px;
    margin: 0 auto
}

#formation-page .head {
    min-height: 25vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    color: #fff
}



.sto{
    padding-top: 12vh !important;
}

.stof{
    padding-top: 8vh !important;
}

#formation-page .head h1 {
    font-size: 35px
}

#formation-page .formation-info {
    width: 60%
}

#formation-page .head article {
    padding-top: 30px
}

#formation-page .head aside {
    width: 40%;
    text-align: center;
    display: flex;
    height: 20vh
}

#formation-page .head aside img {
    display: block;
    margin: auto;
    max-width: 30%;
    object-fit: cover
}

#formation-page .d-flex {
    display: flex;
    flex-wrap: wrap
}

.formation-body .container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #333333;
}

.formation-body .d-flex {
    display: flex;
    flex-wrap: wrap
}

.formation-body #body-left {
    width: 30%;
    text-align: justify;
}

.formation-body #body-left div{
    margin: 30px 0;
}

.formation-body #body-right {
    width: 65%;
    padding-left: 5%
}

.formation-body h3 {
    margin-top: 30px;
    margin-bottom: 15px
}

.formation-body .debouche ul {
    padding-left: 30px
}

.formation-body .debouche ul li {
    padding-top: 8px
}

.formation-body .pdf a {
    display: block;
    padding: 15px;
    color: #233064;
    text-decoration: none;
    background-color: #96a7dd;
    text-align: center;
    font-weight: bolder;
    margin: 15px 0;
}

.table {
    width: 100%;
    margin-bottom: 15px
}

.table td,
.table th {
    padding: 5px
}

.table tr:nth-child(odd) {
    background-color: #ececec
}

.table td:nth-child(2n) {
    text-align: center
}

.table thead tr {
    background-color: #fff !important;
    text-align: center;
}

.table tbody{
    text-align: left;
}


.tab-pane {
    width: 100%;
    display: none
}

.tab-content .active {
    display: block
}

.programme h4{
    margin: 15px 0;
}

.programme .tab-pane ul{
    margin: 15px 0 15px 25px;
}

.programme .tab-pane ul li{
    margin-top: 5px;
}

.programme .tab-pane{
    text-align: justify;
}

.programme .nav {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ddd
}

.programme .nav li {
    padding: 10px
}

.programme .nav a {
    text-decoration: none
}

.programme .nav .active a {
    color: #000
}

.programme .nav .active {
    border: 1px solid;
    border-color: #ddd #ddd #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: relative;
    top: 1px
}


.programme .col-md-12{
    margin: 15px 0;
}

/************************* Actu ************************/
.page-home{
   
    padding-top: 90px;
    padding-bottom: 5vh;
    background: radial-gradient(#3f407b, #2c2d59);
    color: white;
}

#article-home{
    min-height: 15vh;
}

.page-body{
    width: 100%;
    min-height: 350px;
    background-color: #fff;
    padding-bottom: 80px;
    color: #333333;
}

.page-home .container,
.page-body .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap
}

.page-body .container a {
    margin-top: 15px;
}

.page-home .container h1{
    padding-top: 5vh;
    width: 100%;
    text-transform : uppercase;
}

.page-home .container a{
    width: 100%;
    color: white;
    text-decoration: none;
    margin-top: 15px;
    font-weight: bolder;
}

.page-home .container p{
    width: 80%;
    padding-top: 15px;
}

#actu-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.articles{
    display: block;
    width: 350px;
    background-color: #ebebeb;
    padding: 15px;
    margin-top: 30px;
    transition: all 1s;
    color: #333333;
    text-decoration: none;
}

.articles:hover{
    transform: scale(1.1);
}

.articles-title{
    margin: 10px 0 5px;
    text-transform : uppercase;
    font-weight: bolder;
}
.articles-date{
    margin: 5px 0 10px;
    font-size: 0.9em;
}

.articles-image img{
    width: 100%;
    height: 200px;
    object-fit: cover;
}

#article{
    width: 95%;
    margin: 0 auto;
    display: block;
}

#article article{
    margin-top: 20px;
    display: block;
    width: 100%;
  
}

#article article p{
    margin: 10px 0;
}

#article article img{
    margin: 15px 0;
    width: 80%;
}

.aligncenter{
    text-align: center;
}

/*********************** inscriptions **********************/

#inscription-home {
    width: 100%;
    min-height: 25vh;
    padding-top: 90px;
    background: radial-gradient(#3f407b, #2c2d59);
    
}


#inscription-home .container {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap
}

#inscription-home #inscription-header {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    padding-bottom: 30px;
    
}

#inscription-home #inscription-header #inscription-info {
    padding-top: 5vh;
    padding-bottom: 5vh;
    color: #fff;
    width: 65%
}

#inscription-home #inscription-header #date-inscription {
    width: 30%;
    display: flex
}

#inscription-home #inscription-header h1,
#inscription-home #inscription-header p {
    margin-bottom: 15px
}

#inscription-info p:nth-of-type(2){
    margin-top: 15px;
}

#inscription-info a{
    color: white;
}

#inscription-home #inscription-header ul {
    margin-left: 15px
}

#inscription-home #date-inscription #boite-inscription {
    margin: auto;
    border: 1px solid #fff
}

#inscription-home #date-inscription #boite-inscription a{
    text-decoration: none;
    font-weight: bolder;
}


#inscription-home #date-inscription #boite-inscription #up {
    padding: 20px;
    text-align: center;
    background-color: #fff;
    color: #353668;
    font-weight: bolder;
    font-size: 20px
}

#inscription-home #date-inscription #boite-inscription #down {
    padding: 20px;
    text-align: center;
    background-color: #353668;
    color: #fff;
    font-weight: bolder;
    font-size: 20px
}

#date-inscription{
    text-transform: uppercase;
}

.obligation {
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap
}

.obligation article {
    padding: 0 50px 0 0;
    flex: 1 1
}

.obligation article h2,
.obligation article p {
    margin-bottom: 15px
}

.obligation article p{
    text-align: justify;
}


hr {
    width: 100%;
    height: 1px;
    background-color: #eee;
    border: none;
    margin-bottom: 30px
}

.complement {
    padding: 0;
}

.complement ul {
    margin: 15px 15px 15px
}

.complement li{
    text-align: justify;
    margin: 5px 0;
}

.complement h3 {
    margin-bottom: 5px;
    text-transform: uppercase;
}

/*********************** contact **********************/

#contact-body .container{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
    }

#contact-body .container{
    justify-content: space-between;
    color: #333333;
    padding-top: 15px;
}

#contact-form{
    width: 48%;
    margin-top: 20px;
}

#contact-form form{
    box-sizing: border-box;
    padding: 15px;
    background-color: #ececec;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

#contact-form form .form-group{
    width: 100%;
}

#contact-form form input, #contact-form form textarea{
    border: none;
    border-bottom: solid 1px silver;
    font-size: 18px;
    background: transparent;
    width: 98%;
    margin: 20px 0;
    color: black;
    padding: 5px;
    font-family: "Montserrat", sans-serif;
}

#contact-form form input:focus, #contact-form form textarea:focus{
    outline: none !important;
    border:1px solid #323386;
    box-shadow: 0 0 10px #719ECE;
}

#contact-form form textarea{
    resize: none;
}

#contact-form form input[type=submit]{
    padding: 8px 0;
    text-align: center;
    width: 200px;
    color: white;
    background-color: #323386;
    border: none;
}

.btn-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.body-calendar td{
    text-align: center;
}

#ete{
    margin-top: 40px;
}

#ouverture{
    width: 48%;
}

#ouverture h2, #ouverture h3{
    margin: 15px 0;
}

#ouverture .table{
    margin: 15px 0;
}

.align-center{
    text-align: center;
}

#map{
    width: 100%;
    margin-top: 5vh;
}

#g_map{
    width: 100%;
    height: 350px;
    border: none;
}

#clickfoot{
    cursor:pointer;
    width:300px;
    line-height: 30px;
    color: #233064;
    background-color: #96a7dd;
    text-align:center;
    
    margin: 15px 0;
}

.form-error{
    color: red;
    font-size: 0.8em;
}

.form-error-last{
    margin-bottom: 15px;
}

/*********************** Jobs *********************************/

#jobs{
    flex-direction: column;
    margin-top: 15px;
}

.jobs{
    margin: 15px 0;
    color: black;
    text-decoration: none;
    display: block;
}

#content-job{
    margin-top: 15px;
}

#job-info{
    margin-top: 30px;
}


/************************ Mentions Légales *********************/
#legal{
    display: block;
}

#legal li{
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
}

#legal h3{
    margin: 10px 0;
}

#legal p{
    text-align: justify;
    margin: 5px 0;
}

/*********************** Service qualité **********************/
#qualite{
    display: block;
    margin-top: 30px;
}

.qualite-content{
    margin-top: 15px;
}

#qualite .infos-file{
    width: 300px;
}

#qualite h3{
    margin: 15px 0; 
}

#qualite p{
    margin: 10px 0;
}

#qualite ul{
    margin-left: 35px;
}

#qualite .source{
    font-size: 0.8em;
}

/*********************** Loading spinner **********************/

.dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 200px auto;
  }
  
  .dot {
    min-width: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #eee;
    margin: 2px;
  }
  
  .dot-1,
  .dot-2,
  .dot-3 {
    animation: dot1 1.4s infinite both;
  }
  
  .dot-2 {
    animation-delay: 0.2s;
  }
  
  .dot-3 {
    animation-delay: 0.4s;
  }
  
  @keyframes dot1 {
    0% {
      background: #f7fafc;
    }
    50% {
      background: #6f747a;
    }
    100% {
      background: #f7fafc;
    }
  }
/*********************** informations **********************/
  
.calendrier{
    width: 100%;
}

.calendrier h1{
    text-align: center;
    margin: 30px 0;
}
.table-calendrier{
    width: 100%;
}

.table-calendrier td{
    text-align: left!important;
}

.infos{
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    flex-wrap: wrap;
}

.infos h3{
    min-height: 20px;
}

.infos-pdf{
    width: 25%;
    padding: 30px;
}

.infos-file{
    display: block;
    color: #233064;
    background-color: #96a7dd;
    text-decoration: none;
    text-align: center;
    font-weight: bolder;
    padding: 15px;
    margin: 5px 0;
}

/******************************* A propos **********************/
.about p{
    margin: 15px 0;
}

/**************************** Partenaires page ***************/

#partners .partners-logo-container{
    margin-top: 40px;
    width: 100%;
    min-height: 40vh;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

#partners .partners-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

#partners .partners-logo a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    width: 100%;
}

#partners .partners-logo img{
    width: 80%;
}

#partners .partners-logo:nth-child(4)
{
    grid-row: span 2;
}


/****************************************** mediaQueries **************************************/
/******************* 1380px ******************/
@media all and (max-width: 1380px)
{
    footer #f-link{
        min-height: 115px;
    }
}



/******************* 1300px ****************/
@media (max-width: 1300px) {
    #header-social{
        display: none;
    }
}

/******************* 1200px ****************/
@media (max-width:1200px) {
    
    #inscription-home, #inscription-header, #inscription-info {
        padding: 40px 15px 15px 15px; 
    }

    .page-home p{
        padding: 15px;
    }

    .page-home .container, .page-body .container{
        width: 100%;
    }

    .page-home h1, .page-home h2, .page-home h3, .page-home a{
        margin: 15px;
    }

   

    .page-body .container{
        width: 97%;
        margin: 0 auto;
    }

    #info-page-body .container{
        padding: 0;
    }

    #formation-page .container,
    #formations-home .container,
    #inscription-home .container,
    .container,
    .formation-body .container{
        width: 100%
    }


    #actu-container{
        justify-content: space-around;
    }

    
   #contact-body .container{
        width: 95%;
    }

    #formations-home .form-control,
    #formations-home h1,
    #formations-home h2 {
        margin: 15px
    }

    #formations-home #formats {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-around
    }

    #formations-home #formats .formation {
        margin-left: 0
    }

    #formations-home .category {
        margin: 5px 15px
    }

    .formation-body .container,
    .head {
        box-sizing: border-box;
        padding-left: 15px;
        padding-right: 15px
    }

    .not-found .container,
    header nav ul {
        width: 100%
    }

    #formations-home
    {
        padding-bottom: 200px
    }

    #organisation,
    footer #service .wrapper, #home-actu {
        width: 100%
    }

    footer #f-link .container{
        width: 99%;
    }

    #contact-form form input[type=submit]{
        width: auto;
        padding: 0 20px;
    }

    .formation-body #body-right {
        width: 65%;
        padding-left: 5%
    }

    .promo-title h1{
        width: 100%;
        text-align: center;
    }

}

/******************* 992px ****************/

@media (max-width:992px) {

    .newYear{
        width: 80% !important;
    }

    header>nav {
        display: none
    }

    #mobile {
        display: block
    }

    #mobile nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap
    }

    #events {
        width: 100%;
    }

    .event {
        margin-top: 30px
    }

    #calendar {
        padding-top: 0
    }

    #formation-page .d-flex aside img {
        max-width: 80% !important
    }

    #formations-home #categories{
        justify-content: center;
    }

    #inscription-home #inscription-header #date-inscription,
    #inscription-home #inscription-header #inscription-info {
        width: 100%
    }

    #inscription-home, #inscription-header {
        padding: 40px 0 0 0; 
    }

    .obligation {
        flex-direction: column
    }

    .obligation article {
        margin: 15px 0
    }

    #contact-form{
        width: 100%;
    }

    #ouverture{
        width: 100%;
    }

    #contact-form form input[type=submit]{
        width: 200px;
        padding: 8px 0;
        margin: auto !important;
    }

    .btn-container{
        width: 100%;
        display: flex;
        justify-content: center !important;
        flex-direction: column;
    }

    #captcha{
        margin: 15px auto !important;
    }

    #clickfoot{
        margin: 15px auto;        
    }

}

/******************* 992px ****************/

@media all and (max-width: 900px)
{
    #cube{
        display: none;
    }

    #mobile-cube{
        display: block;
        width: 80%;
    }
}

/******************* 768px ******************/
@media all and (max-width: 768px)
{
    footer .container-logos{
        justify-content: center;
    }

    footer #service .wrapper {
        grid-gap: 40px
    }

    footer #service .wrapper div {
        grid-column: 1/span 8;
        text-align: center
    }

    footer #f-link .container {
        flex-wrap: wrap;
        flex-direction: row
    }

    footer #f-link #links {
        padding: 10px
    }

    footer #f-link #copy {
        width: 100%;
        text-align: center
    }
}


/******************* 660px ****************/

@media (max-width:660px) {
    #organisation>a {
        grid-column: 1/span 3
    }

    #home{
        padding-bottom: 100px;
    }

    #home-actu{
        position: relative;
        top: -130px;
    }

    .home-actu{
        grid-column: 1/span 3;
    }

    #calendar {
        position: relative;
        top: -130px;
        z-index: 1
    }

    .event {
        margin: 30px auto 0
    }

    #info {
        margin-top: -110px
    }

    #pratiques {
        left: 50%;
        transform: translateX(-50%)
    }

    

    .title h3 {
        width: 90%;
        text-align: center
    }

   

    .formation-body .d-flex {
        flex-direction: column !important;
        justify-content: center
    }

    .formation-body #body-left {
        width: 80%;
        margin: 0 auto
    }

    #body-right,
    .formation-body #body-right {
        width: 80%;
        padding: 0;
        margin: 0 auto
    }

    .formation-body .container {
        padding-left: 0;
        padding-right: 0
    }

    #formation-page .d-flex {
        flex-direction: column-reverse;
        justify-content: center
    }

    .formation-info {
        margin: auto;
        width: 80% !important
    }

    #formation-page .d-flex aside {
        margin: auto
    }

    #formation-page .d-flex aside img {
        max-width: 80% !important
    }

    #contact-form{
        width: 100%;
        margin: 15px auto;
    }

    .dots {
        margin: 100px auto;
      }

      .infos-pdf{
        width: 100%;
        padding: 15px;
    }
    
    .infos h3{
       min-height: 20px;
    }

    #partners .partners-logo-container{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    #partners .partners-logo img{
        width: 60%;
    }
        
    #partners .partners-logo:nth-child(4)
    {
        grid-row: span 1;
    }
    
    
}

/******************* 500 px ***************/
@media (max-width: 500px) {
    #cube{
        width: 80%;
    }

    .sto{
        padding-top: 16vh !important;
    }
  
    
}


/******************* 450px ****************/

@media (max-width:450px) {
   
    .table-contact{
        font-size: 10px;
    }
    footer #f-link{
        min-height: 230px;
    }
}

@media all and (max-width: 325px){
   .title h1{
    font-size: 10vw;
   }
}
@media all and (max-width: 285px){
    #showTime{
        font-size: 5vw;
    }

    #pratiques{
        width: 100%;
        left:0;
        transform: translateX(0);
    }

    footer #service .wrapper{
        display: block;
    }

    
}

@media all and (max-width: 220px){
    #organisation h1{
        font-size: 10vw;
    }

    .container-logos{
        flex-direction: column;
    }

    .footer-logo{
        border: none !important;
        padding: 10px 0 !important;
    }

    #pratiques{
        height: auto;
    }

}


@media (max-height: 735px){
    .sto{
        padding-top: 15vh !important;
    }
    .stos{
        padding-top: 20vh !important;
    }
    .stosm{
        padding-top: 12vh !important;
    }
}

@media (max-height: 670px){
    
    .sto{
        padding-top: 18vh !important;
    }

    .stosm{
        padding-top: 20vh !important;
    }
}

@media (max-width:360px) {
   
    footer #f-link{
        min-height: 250px;
    }
}

@media (min-height: 1000px){
    .page-home .container h1{
        padding-top: 2vh;
    }

    #inscription-home, #inscription-header {
        padding: 10px 0 0 0; 
    }
}

@media (min-height: 1000px) and (max-width: 796px){
    #inscription-home, #inscription-header {
        padding: 40px 0 0 0; 
    }
}


@media (min-height: 1000px) and (max-width: 500px){
    .page-home .container h1{
        padding-top: 1vh;
    }
}




